import React, { createContext, useState, useEffect } from "react";

const CartContext = createContext();

const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState(() => {
    const storedCartItems = localStorage.getItem("cartItems");
    return storedCartItems ? JSON.parse(storedCartItems) : [];
  });

  const [originalPrices, setOriginalPrices] = useState(() => {
    const storedOriginalPrices = localStorage.getItem("originalPrices");
    return storedOriginalPrices ? JSON.parse(storedOriginalPrices) : {};
  });

  const [editedPrices, setEditedPrices] = useState({});
  const [lastAuthorizedPrices, setLastAuthorizedPrices] = useState({});
  const [storedPassword, setStoredPassword] = useState("");
  const [totalSgstAmount, setTotalSgstAmount] = useState(0); // State to store total SGST amount
  const [totalCgstAmount, setTotalCgstAmount] = useState(0); // State to store total CGST amount

  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  }, [cartItems]);

  useEffect(() => {
    localStorage.setItem("originalPrices", JSON.stringify(originalPrices));
  }, [originalPrices]);

  // Function to calculate tax for an item
  const calculateItemTax = (item) => {
    let sgstAmount = 0;
    let cgstAmount = 0;

    item.tax.forEach((tax) => {
      if (tax.tax_type === "Output Tax SGST - NPL") {
        sgstAmount = (item.product_price * (parseFloat(tax.custom_tax_percentage) / 100)) * item.quantity;
      }
      if (tax.tax_type === "Output Tax CGST - NPL") {
        cgstAmount = (item.product_price * (parseFloat(tax.custom_tax_percentage) / 100)) * item.quantity;
      }
    });

    return { sgstAmount, cgstAmount };
  };

  // Function to update SGST and CGST totals when the cart items change
  const updateTotalTaxes = () => {
    let sgstTotal = 0;
    let cgstTotal = 0;

    cartItems.forEach((item) => {
      const { sgstAmount, cgstAmount } = calculateItemTax(item);
      sgstTotal += sgstAmount;
      cgstTotal += cgstAmount;
    });

    setTotalSgstAmount(sgstTotal);
    setTotalCgstAmount(cgstTotal);
  };

  // Use useEffect to update taxes whenever cart items change
  useEffect(() => {
    updateTotalTaxes();
  }, [cartItems]);

  const addItemToCart = (item) => {
    setCartItems((prevItems) => {
      const itemIndex = prevItems.findIndex((prevItem) => prevItem.id === item.id);

      if (itemIndex >= 0) {
        const updatedItems = [...prevItems];
        updatedItems[itemIndex].quantity += item.quantity;

        if (item.isScanned && !updatedItems[itemIndex].price) {
          updatedItems[itemIndex].price = item.price;
        }

        return updatedItems;
      } else {
        const newItem = {
          ...item,
          price: item.isScanned ? item.price : (item.price || 0),
        };

        setOriginalPrices((prevPrices) => {
          if (!prevPrices[item.id]) {
            return { ...prevPrices, [item.id]: item.product_price };
          }
          return prevPrices;
        });

        return [...prevItems, newItem];
      }
    });
  };

  const removeItemFromCart = (itemId) => {
    setCartItems((prevItems) => {
      const updatedItems = prevItems.filter((item) => item.id !== itemId);

      setOriginalPrices((prevPrices) => {
        const { [itemId]: _, ...rest } = prevPrices;
        return rest;
      });

      setEditedPrices((prevPrices) => {
        const { [itemId]: _, ...rest } = prevPrices;
        return rest;
      });

      setLastAuthorizedPrices((prevPrices) => {
        const { [itemId]: _, ...rest } = prevPrices;
        return rest;
      });

      return updatedItems;
    });
  };

  const resetCart = () => {
    setCartItems([]);
    setOriginalPrices({});
    setEditedPrices({});
    setLastAuthorizedPrices({});
    setTotalSgstAmount(0);
    setTotalCgstAmount(0);
  };

  const completeOrder = () => {
    setCartItems([]);
    setOriginalPrices({});
    setEditedPrices({});
    setLastAuthorizedPrices({});
    setTotalSgstAmount(0);
    setTotalCgstAmount(0);
  };

  return (
    <CartContext.Provider
      value={{
        cartItems,
        addItemToCart,
        removeItemFromCart,
        resetCart,
        completeOrder,
        totalSgstAmount,
        totalCgstAmount,
        setCartItems,
        setEditedPrices,
        setLastAuthorizedPrices,
        setStoredPassword,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export { CartProvider, CartContext };
